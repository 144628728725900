import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

// import Layout from "../components/layout"
// import SEO from "../components/seo"

// Hooks
// import useTranslation from "../hooks/useTranslation"

// Icons
// import MoneyIcon from "../assets/icons/money.svg"
// import EUIcon from "../assets/icons/eu.svg"
// import ClockIcon from "../assets/icons/clock.svg"

// Components
// import SectionTitle from "../components/section-title"
// import GetStartedSection from "../components/get-started-section"
// import FeaturesSection from "../components/features-section"

import NotFoundPage from "./404"


// Styles
import "../styles/pages/payment-service-provider-for-ecommerce.scss"


// const HeroSection = ({ imageFluid }) => {
//   const { t } = useTranslation()
//   return (
//     <section className="section is-hero has-margin-bottom-6">
//       <div className="container">
//         <div className="columns ">
//           <div className="column is-5">
//             <h1 className="title main-title has-text-white is-spaced">
//               {t("Bet on Twispay")}
//             </h1>
//             <h2 className="subtitle main-subtitle has-text-white has-margin-bottom-6">
//               {t(
//                 "Licensed Acquirer and Payment Services Provider for Betting and Gambling Merchants"
//               )}
//             </h2>
//           </div>
//           <div className="column">
//             <Img fluid={imageFluid} />
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }
// const DesignedSection = () => {
//   const { t } = useTranslation()
//   const items = [
//     {
//       icon: <MoneyIcon />,
//       title: t("Ready to Go"),
//       description: t("Start accepting the most popular payment methods"),
//     },
//     {
//       icon: <EUIcon />,
//       title: t("Regulatory Compliance"),
//       description: t("Meeting gambling regulations across the European Union"),
//     },
//     {
//       icon: <ClockIcon />,
//       title: t("Swift Onboarding"),
//       description: t("All documents submitted, start processing in no time"),
//     },
//   ]
//   return (
//     <section className="section has-margin-bottom-6">
//       <div className="container">
//         <SectionTitle
//           title={t("Licensed for iGaming")}
//           subtitle={t(
//             "We have built a fully compliant gateway to provide gambling and betting merchants with a reliable and secure one-stop-shop for online transactions"
//           )}
//         />
//         <div className="columns  has-text-centered ">
//           {items.map(item => (
//             <div key={item.title} className="column">
//               <span className="icon is-larger has-margin-bottom-5 has-text-primary">
//                 {item.icon}
//               </span>
//               <h4 className="title is-4 ">{item.title}</h4>
//               <p className="subtitle is-5 has-text-secondary">
//                 {item.description}
//               </p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   )
// }

const ECommercePage = ({ data: images }) => {
  // const { t } = useTranslation()
  return (
    // <Layout
    //   stripeClass="is-red-violet"
    //   pageName="payment-services-betting-gambling"
    // >
    //   <SEO title={t("Payment Services for Betting Operators")} />
    //   <HeroSection imageFluid={images.dice.childImageSharp.fluid} />
    //   <DesignedSection />
    //   <FeaturesSection
    //     title={t("Adaptive Payment Experience")}
    //     subtitle={t(
    //       "Orchestrated through a powerful API, easy to integrate, and developer friendly"
    //     )}
    //     images={images}
    //     invertImages
    //   />

    //   <GetStartedSection
    //     title={t("Not Just a Payment Service Provider")}
    //     subtitle={t("Fine-tune your online shopping experience with Twispay")}
    //   />
    // </Layout>
    <NotFoundPage/>
  )
}

export const images = graphql`
  query {
    dice: file(relativePath: { eq: "gambling-hero.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    buildinPhone: file(relativePath: { eq: "home-builtin-phone.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    secureImage: file(relativePath: { eq: "payment-modules-secure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    checkoutImage: file(relativePath: { eq: "checkout.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    checkoutDarkImage: file(relativePath: { eq: "checkout_dark.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    shopImage: file(relativePath: { eq: "ecommerce-shop.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    dashboardImage: file(relativePath: { eq: "ecommerce-dashboard.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default ECommercePage
